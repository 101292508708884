@import "../../assets/styles/theme";

.modalWrapper {
    width: 50vw;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 0.6rem;
    box-shadow: 0 0 7.5px 0 rgba($black-color, 0.1);
}
